import { useContext, FormEvent, useState, useEffect } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { Button, MenuButton } from "../Components/Button";
import { H2 } from "../Components/H2";
import { Input } from "../Components/Input";
import { Text } from "../Components/Text";
import { SocketContext } from "../context/socket";
import { Alert } from "./ScreenSelector";

interface Props {
  roomCode: string;
  setRoomCode: Function;
  alert?: Alert;
}

export const JoinRoom = (props: Props) => {
  const { roomCode, setRoomCode, alert } = props;
  const socket = useContext(SocketContext);

  const [error, setError] = useState(alert?.severity === "error");

  useEffect(() => {
    setError(!!alert?.severity);
  }, [alert]);

  useEffect(()=>{
    socket?.emit("join", {
      code: roomCode,
    });
  },[])

  const emitJoin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    socket?.emit("join", {
      code: roomCode,
    });
  };

  return (
    <Wrapper animate>
      <H2>Welcome to HiKi</H2>
      <Box onFormSubmit={emitJoin}>
        <div>
          <Input
            error={error}
            onClick={() => setError(false)}
            placeholder={"Enter roomcode"}
            onChange={(e) => {
              setRoomCode(e.target.value);
            }}
            value={roomCode.toString()}
          />
        </div>
        <MenuButton type="submit">Join room</MenuButton>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(AnimatedBackground)`
  position: relative;
  top: -1000px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: center;
  // flex-direction: column;
`;

const ErrorWrapper = styled.div``;

const Error: React.FC = ({ children }) => <ErrorWrapper></ErrorWrapper>;
