import { RouteComponentProps } from "@reach/router";
import { useContext, useEffect, useState } from "react";
import { Input } from "../Components/Input";
import { SocketContext } from "../context/socket";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { H2 } from "../Components/H2";
import { Button, MenuButton } from "../Components/Button";
import { colors, AlternativeColors } from "../styles";
import { Highscore, User } from "./Lobby";
import { randomInt } from "crypto";
import { Player } from "../Components/Player";


export const Admin = (props: RouteComponentProps & {playerNick?:string}) => {
  const {playerNick} = props;
  const socket = useContext(SocketContext);
  const [selected, setSelected] = useState("");
  const [nick, setNick] = useState("");
  const [textMsgs, setTextMsgs] = useState<string[]>([]);
  const [highscore, setHighscore] = useState<Highscore>([]);
  const [question, setQuestion] = useState<string>("");
  const [roomCode, setRoomCode] = useState("");
  const [show, setShow] = useState(true);
  const [playerCode, setPlayerCode] = useState("");
  const [alternatives, setAlternatives] = useState([]);
  const symbol = ["A", "B", "C", "D"];
  const [gameState, setGameState] = useState<
    "code" | "lobby" | "question" | "score" | "finished"
  >("code");
  const [time, setTime] = useState<number>(0);

  const [animateBackground, toggleAnimateBackground] = useState<boolean>(true);

  const alternativeColors: AlternativeColors[] = [
    "red",
    "blue",
    "yellow",
    "green",
  ];

  useEffect(() => {
    socket.on("highscore", (data) => {
      setHighscore(data.highscore);
      if (data.status === "between question") {
        setGameState("score");
      }
      if (data.status === "finished") {
        setGameState("finished");
      }
    });
    socket.on("question", (data) => {
      toggleAnimateBackground(false);
      setQuestion(data.question);
      if (data.alternatives) {
        setAlternatives(data.alternatives);
      }
    });
    socket.on("room you controll", (data) => {
      setPlayerCode(data.room);
      setGameState("lobby");
    });
    socket.on("set nick", (data) => {
      console.log("data: ", data);
      setNick(data);
      console.log("nick: ", nick);
      console.log("playerNick: ", playerNick);
    });
    socket.on("not admin", () => {
      alert("not admin");
    });
    socket.on("tick", (data) => {
      setTime(data.time);
    });
    socket.on("show idle message", (data) => {
      const newMsgs = textMsgs;
      newMsgs.push(data.newMessage);
      setTextMsgs(newMsgs);
    });
  }, []);

  useEffect(() => {
    socket.emit("gethighscore");
  }, []);

  useEffect(()=>{
    socket?.emit("admin", { code: 31289797 });
    setRoomCode("")
  },[])

  document.addEventListener('nickChange', function (e) {
    console.log("nickChange", e);
  }, false);

  const toggleShow = () => {
    console.log("Nick: ", nick);
    if (show === true){
      setShow(false)
      return;
    }
    setShow(true);
  }

  return (
    <>
      {gameState === "code" && (
        <HiddenAdminWrapper animate>
          <H2>Welcome admin (?)</H2>
          <Box>
            <Input
              placeholder={"Super secret admin code"}
              type="password"
              onChange={(e) => {
                setRoomCode(e.target.value);
              }}
              value={roomCode}
            />
            <MenuButton
              onClick={() => {
                socket?.emit("admin", { code: roomCode });
                setRoomCode("");
              }}
            >
              Enter
            </MenuButton>
          </Box>
        </HiddenAdminWrapper>
      )}
      {gameState === "lobby" && (
        <AnimatedBackground animate>
          <LobbyContainer>
            {/* <H2>People in space: {highscore.length}</H2> */}
            <Players>
              {[...highscore].reverse().map((score: User, index: number) => (
                //<Player key={index}>
                  // <PlayerCircle onClick={() => {toggleShow()}}>
                  <Player id={index} selected={selected} setSelected={setSelected} message={score.nick} playerNick={playerNick}>
                    <PlayerCircle onClick={() => {toggleShow()}}>
                      {/* <PlayerName show={show}> */}
                      {/* <PlayerName>
                          {score.nick}
                      </PlayerName> */}
                    </PlayerCircle>
                </Player>
              ))}
            </Players>
          </LobbyContainer>
        </AnimatedBackground>
      )}
      {gameState === "question" && (
        <Wrapper>
          <H2>Time ticks: {time}</H2>
          <H2>Question: {question}</H2>
          <Border />
          <Alternatives>
            {alternatives.map((alternative, index) => {
              return (
                <Alternative alternative={alternativeColors[index]}>
                  <H2>
                    {symbol[index]}: {alternative}
                  </H2>
                </Alternative>
              );
            })}
          </Alternatives>
          {textMsgs.length > 0 && (
            <MessageDiv>
              <MessageSon>
                {textMsgs.map((msg) => {
                  return <MessageText>{msg}</MessageText>;
                })}
              </MessageSon>
            </MessageDiv>
          )}
          <div style={{ position: "fixed", left: 0, top: 0 }}>
            <Button
              onClick={() => {
                socket.emit("skip", { code: playerCode });
              }}
            >
              Skip question
            </Button>
          </div>
        </Wrapper>
      )}
      {gameState === "finished" && (
        <Wrapper>
          <H2>Highscore - Game finished</H2>
          {highscore
            .sort((a, b) => a.score - b.score)
            .reverse()
            .map((score: { nick: string; score: number }, index) => {
              return (
                <PlayerNameLastScreen index={index}>
                  {index === 0 && "⭐"}️{index === 1 && "🥈"}️
                  {index === 2 && "🥉"}️
                  {score.nick.length > 20
                    ? score.nick.slice(0, 20) + "..."
                    : score.nick}
                  : {score.score}
                  {index === 0 && "⭐"}️{index === 1 && "🥈"}️
                  {index === 2 && "🥉"}️
                </PlayerNameLastScreen>
              );
            })}
          <div style={{ position: "fixed", right: 10 }}>
            <Button
              onClick={() => {
                socket.emit("reset game", { code: playerCode });
                window.location.reload();
              }}
            >
              Leave the room
            </Button>
          </div>
        </Wrapper>
      )}
      {gameState === "score" && (
        <Wrapper>
          <H2>Highscore</H2>
          {highscore
            .sort((a, b) => a.score - b.score)
            .reverse()
            .map((score: { nick: string; score: number }, i) => {
              return (
                <PlayerCircle>
                  {i + 1}.{" "}
                  {score.nick.length > 20
                    ? score.nick.slice(0, 20) + "..."
                    : score.nick}
                  : {score.score}
                </PlayerCircle>
              );
            })}
          <div style={{ position: "fixed", right: 10 }}>
            <Button
              onClick={() => {
                socket.emit("next question", { code: playerCode });
                setGameState("question");
                setTextMsgs([]);
              }}
            >
              Next Question
            </Button>
          </div>
        </Wrapper>
      )}
    </>
  );
};

const PlayerName = styled.div`
`

// const randomIntBetween100and1000 = () => {

//   // return Math.floor(Math.random() * (1000 - 100 + 1)) + 100;
//   return Math.floor(Math.random() * (100 - 100 + 1)) + 10;
// }

// const randomIntBetween10and100 = () => {
//   return Math.floor(Math.random() * (90 - 10 + 1)) + 10;
// }

// const randomHexColor = () => {
//   return "#" + Math.floor(Math.random() * 16777215).toString(16);
// }

// const randomDotColor = () => {
//   const li = ["#972020", "#F9E31C", "#BF63EB", "#67E48A", "#0E3FEF", "#27E43A", "#F68512", "#A7E0FF", "#772AC1", "#FDC306", "#FF9393", "#A9B7FF", "#DA15EB"];
//   return li[Math.floor(Math.random() * li.length)];
// }

const Players = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background-color: red;
`;

// const Player = styled.div`
//   flex-grow: 1;
//   padding: 20px 40px;
//   text-align: center;
//   background-color: blue;
// `;

const PlayerCircle = styled.h3`
`;

const PlayerNameLastScreen = styled.h3`
  color: #fff;
  font-size: 28px;
  line-height: 30px;
  ${(props: { index: number }) => props.index === 0 && "font-size: 42px;"}
  ${(props: { index: number }) =>
    props.index === 1 && "font-size: 36px; line-height: 40px;"}
`;

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
`;

const HiddenAdminWrapper = styled(AnimatedBackground)`
  position: relative;
  top: -1000px;
`;

const Alternatives = styled.div`
  width: 90vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

interface AltProps {
  alternative: AlternativeColors;
}

const Alternative = styled.div`
  width: 45%;
  background-color: ${(props: AltProps) => colors[props.alternative]};
  margin: 24px;
  border-radius: 3px;
  border: 2px solid black;
`;

const Border = styled.hr`
  width: 90%;
`;

const Top = styled.div`
  border-bottom: 1px solid white;
`;

const LobbyContainer = styled.div`
  padding: 20px;
`;

const MessageDiv = styled.div`
  position: relative;
  height: 300px;
  width: 500px;
  overflow: hidden;
`;

const MessageSon = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
`;

const MessageText = styled.p`
  font-size: 24px;
  background-color: white;
  padding: 20px 10px;
  margin: 10px 0px;
`;
