import styled from "styled-components";
import { colors } from "../styles";

export const Button = styled.button`
  padding: 12px 24px;
  width: 100%;
`;

interface Props {
  color: "red" | "yellow" | "blue" | "green";
}

export const AnswerButton = styled.button`
  background-color: ${(props: Props) => colors[props.color]};
  color: white;
  border: none;
  border-radius: 3px;
  padding: 48px 48px;
  width: 100%;
  margin: 8px;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
  }
`;

export const MenuButton = styled.button`
  padding: 12px 24px;
  width: 100%;
  font-size: 16px;
  background: var(--gray);
  color: var(--white);
  min-height: 32px;
  border: none;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
`;
