import { FormEvent, useContext, useState } from "react";
import styled from "styled-components";
import { AnimatedBackground } from "../Components/AnimatedBackground";
import { Box } from "../Components/Box";
import { MenuButton } from "../Components/Button";
import { H2 } from "../Components/H2";
import { Input } from "../Components/Input";
import { SocketContext } from "../context/socket";

interface Props {
  roomTitle: string;
  saveNick: Function;
}

const Wrapper = styled(AnimatedBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const SetNick = (props: Props) => {
  const { roomTitle } = props;
  const socket = useContext(SocketContext);
  const [nick, setNick] = useState("");

  const emitNick = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    socket?.emit("set nick", {
      nick,
    });
    const event = new CustomEvent('nickChange', { detail: {nick} });
    document.dispatchEvent(event);
    props.saveNick(nick);
  };

  return (
    <Wrapper animate={true}>
      <H2>Welcome to {roomTitle}</H2>
      <Box onFormSubmit={emitNick}>
        <Input
          placeholder="How are you feeling"
          onChange={(e) => {
            setNick(e.target.value);
          }}
          value={nick.toString()}
        />
        <MenuButton type="submit">Join HiKi</MenuButton>
      </Box>
    </Wrapper>
  );
};
