export const colors = {
  blackish: "#333333",
  // white: "#ffffff",
  white: "#000000",
  red: "#FF7D6F",
  yellow: "#FFCB6A",
  green: "#99D367",
  blue: "#81A4FF",
  pink: "#FF9FF0",
};

export type AlternativeColors = "red" | "yellow" | "blue" | "green";
