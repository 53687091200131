import React, { Children, useState, useEffect } from "react";
// import styled, { keyframes } from "styled-components";
import styled from "styled-components";

export let PlayerCircle = styled.div`
  z-index: 9999;
  position: absolute;
  left: ${props => props.xPos}vw;
  top: ${props =>props.yPos}vh;
  color: #fff;
  color: black;
  font-size: 28px;
  width: 1em;
  height: 1em;
  /* background-color: #d22fa1; */
  background-color: ${props => props.circleColor};
  border-radius: 50%;
  & :hover{
    cursor: pointer;
  }
`;

const PlayerName = styled.div`
  display: ${props => props.showName ? "block" : "none"};
  & :hover{
    cursor: pointer;
  }
  width: max-content;
  position: relative;
  top: 25px;
  left: -90px;
  background-color: #FF9393;
  background-color: ${props => randomDotColor()};
  color: white;
  border-radius: 0.5em;
  padding: 0.45em;
  font-size: 0.6em;
`

const IsYou = styled.div`
    z-index: 10000;
    display: ${props => props.isYou ? "block" : "none"};
    font-size: 14px;
    position: relative;
    top: -20px;
  `

const randomIntBetween10and100 = () => {
  return Math.floor(Math.random() * (90 - 10 + 1)) + 10;
}

const randomDotColor = () => {
  const li = ["#972020", "#F9E31C", "#BF63EB", "#67E48A", "#0E3FEF", "#27E43A", "#F68512", "#A7E0FF", "#772AC1", "#FDC306", "#FF9393", "#A9B7FF", "#DA15EB"];
  return li[Math.floor(Math.random() * li.length)];
}

export const Player = ({ id, children, selected, setSelected, message, playerNick}) => {
    let isSelected = selected === id;
    let [xPos,setXpos ] = useState(randomIntBetween10and100())
    let [newXpos, setNewXpos] = useState()
    let [yPos,setYpos] = useState(randomIntBetween10and100());
    let [circleColor,setcircleColor] = useState(randomDotColor());
    let [isTheChoosenOne,setIsTheChoosenOne] = useState(false);

  console.log("PlayerNick: ", playerNick)
  console.log("message: ", message)

    const isSelf = () => {
      if (message === playerNick){
        setIsTheChoosenOne(true);
      }
    }

    const createNewXPos = () => {
      console.log(xPos);
      let newXpos = xPos + 5;
      setNewXpos(newXpos)
    }

    useEffect(()=>{
      isSelf();
      setXpos(randomIntBetween10and100());
      setYpos(randomIntBetween10and100())
      setNewXpos(randomIntBetween10and100())
      setcircleColor(randomDotColor())
      // createNewXPos()
      console.log("xPos: ", xPos);
      console.log("yPos: ", yPos);
      console.log("newXpos: ", newXpos);
    }, [])

    const selectDeselect = () => {
      console.log("isSelected: ", isSelected);
      if (isSelected) {
        setSelected(null);
      } else {
        setSelected(id);
      }
    }
    
    const handleClick = () => {
      selectDeselect();
    }
    return (
      // <MoveAround xPos={xPos} yPos={yPos} newXpos={newXpos}>
        <PlayerCircle xPos={xPos} yPos={yPos} newXpos={newXpos} circleColor={circleColor}
            onClick={() => handleClick()}
            selected={isSelected} isTheChoosenOne={isTheChoosenOne}>
            {/* {text} */}
            {isTheChoosenOne &&
            <IsYou isYou={isTheChoosenOne}>
              You
              </IsYou>}
            <PlayerName showName={isSelected}>
              {message}
            </PlayerName>
            {children}
          </PlayerCircle>
        // </MoveAround>
    );
  };