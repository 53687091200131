import styled, { keyframes, css } from "styled-components";
import { colors } from "../styles";

const colorTransition = keyframes`
  0% {
    /* background-color: ${colors.yellow}; */
    background-color: #00a8a3;
    background-color: white;
  } 
  25% {
    /* background-color: ${colors.blue}; */
  }
  50% {
    /* background-color: ${colors.green}; */
    background-color: #4ed9d9;
    background-color: white;
  }
  75% {
    /* background-color: ${colors.red}; */
  }
  100%{
    /* background-color: ${colors.yellow} */
    background-color: #00a8a3;
    background-color: white;
  }
`;

interface AnimationProps {
  animate?: boolean;
}

export const AnimatedBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  //background-color: white;
  ${(props: AnimationProps) =>
    props.animate
      ? css`
          animation: 20s ${colorTransition} ease-in-out infinite;
        `
      : css`
          background-color: #1f1b24;
        `}
`;
