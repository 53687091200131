import { FormEventHandler } from "react";
import styled from "styled-components";
import { colors } from "../styles";

const StyledBox = styled.div`
  // border: 2px solid ${colors.blackish};
  border-radius: 10px;
  background-color: ${colors.white};
  background-color: #FFF9BE;
`;

const InnerBox = styled.div`
  margin: 24px 36px;
`;

const InnerForm = styled.form`
  margin: 24px 36px;
`;

interface Props {
  onFormSubmit?: FormEventHandler<HTMLFormElement>;
}

export const Box: React.FC<Props> = ({ children, onFormSubmit }) => {
  return (
    <StyledBox>
      {onFormSubmit ? (
        <InnerForm onSubmit={onFormSubmit}>{children}</InnerForm>
      ) : (
        <InnerBox>{children}</InnerBox>
      )}
    </StyledBox>
  );
};
