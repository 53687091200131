import styled from "styled-components";
import { colors } from "../styles";

export const H2 = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  margin-top: 64px;
  color: ${colors.white};
  font-weight: bold;
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; */
`;
