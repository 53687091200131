import { RouteComponentProps } from "@reach/router";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../context/socket";
import { JoinRoom } from "./JoinRoom";
import { Lobby } from "./Lobby";
import { SetNick } from "./SetNick";
import { Router } from "@reach/router";
import { Admin } from "./Admin";

type RoomName = "joinroom" | "setnick" | "lobby" | "playing";
export type Alert = {
  severity: "info" | "warning" | "success" | "error";
  text: string;
};
interface Room {
  name: RoomName;
  props?: {
    alert?: Alert;
  };
}


export const ScreenSelector = (props: RouteComponentProps) => {
  const socket = useContext(SocketContext);

  const [gameState, setGameState] = useState<Room>({ name: "joinroom" });
  const [roomTitle, setRoomTitle] = useState("");
  const [roomCode, setRoomCode] = useState<string>("1337");
  const [playerNick, setPlayerNick] = useState<string>("");

  useEffect(() => {
    socket.on("ready", () => setGameState({ name: "lobby" }));

    socket.on("joined room", (data) => {
      if (data.code === false) {
        console.warn("Not a room");
        setGameState({
          name: "joinroom",
          props: {
            alert: { severity: "error", text: "No room with that code exists" },
          },
        });
      } else {
        setRoomTitle(data?.title ?? "");
        setGameState({ name: "setnick" });
      }
    });
  }, []);

  const selectScreen = () => {
    switch (gameState.name) {
      case "joinroom":
        return (
          <JoinRoom
            {...gameState.props}
            roomCode={roomCode}
            setRoomCode={setRoomCode}
          />
        );
      case "setnick":
        return <SetNick saveNick={setPlayerNick} roomTitle={roomTitle} />;
      case "lobby":
        return <Admin playerNick={playerNick}/>;
      default:
        return <p>I did a bad</p>;
    }
  };

  return <>{selectScreen()}</>;
};
