import socketIOClient from "socket.io-client";
import React from "react";

const backendUrl = () => {
  if (window.location.hostname === "frontend.hiki.test.iterate.no") {
    return "https://backend.hiki.test.iterate.no";
  } else if (window.location.hostname === "frontend.hiki.app.iterate.no") {
    return "https://backend.hiki.app.iterate.no";
  } else {
    return "localhost:3030";
  }
};

export const socket = socketIOClient(backendUrl(), {
  withCredentials: true,
});

export const SocketContext = React.createContext(socket);
